export const ArrowLeft = () => {
  return (
    <svg width="50%" height="auto" viewBox="0 0 24 24" fill="none">
      <path
        d="M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.93934 10.9393ZM22 10.5L2 10.5L2 13.5L22 13.5L22 10.5Z"
        fill="white"
      />
    </svg>
  );
};
