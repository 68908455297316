// import { Colors } from 'theme/Colors'

export enum ButtonTypes {
  Primary = "primary",
  Dark = "dark", // dark
}

export enum ButtonSizes {
  Large = "large",
  Medium = "medium",
  Small = "small",
}

export const buttonSizeValues = {
  [ButtonSizes.Large]: {
    height: 48,
    maxWidth: 212,
    minWidth: 112,
  },
  [ButtonSizes.Medium]: {
    height: 40,
    maxWidth: 150,
    minWidth: 96,
  },
  [ButtonSizes.Small]: {
    height: 32,
    maxWidth: 180,
    minWidth: 80,
  },
};

export const buttonColorValues = {
  [ButtonTypes.Primary]: {
    color: "#FFFFFF",
    invertedColor: "#000000",
    hover: "#ffffff4d",
  },
  [ButtonTypes.Dark]: {
    color: "#000000",
    invertedColor: "#FFFFFF",
    hover: "#b4b4b44d",
  },
};
