import { DownCarrot } from "../../assets/icons/downCarrot";
import * as Styled from "./style";
import Product from "../Product";
import MobileAppCta from "../MobileApp";
import Footer from "../../components/footer";
import { useSmallScreenListener } from "../../hooks/useSmallScreenListener";

const watchMyItems = [" Stuff", " Boat", " Camper", " RV", " Lock"];

var ReactRotatingText = require("react-rotating-text");

const Home = () => {
  const smallScreen = useSmallScreenListener(945);

  return (
    <Styled.HomeContainer>
      <Styled.Splash>
        <Styled.SplashText smallScreen={smallScreen}>
          <Styled.SplashTextPreText smallScreen={smallScreen}>
            Watch my
          </Styled.SplashTextPreText>
          <Styled.SplashTextPostText smallScreen={smallScreen}>
            <ReactRotatingText
              pause={2000}
              emptyPause={1000}
              typingInterval={100}
              deletingInterval={100}
              items={watchMyItems}
            />
          </Styled.SplashTextPostText>
        </Styled.SplashText>
        <Styled.CenterWrapper>
          <Styled.DownCarrotContainer
            onClick={() =>
              document.getElementById("productSection")?.scrollIntoView()
            }
          >
            <DownCarrot />
          </Styled.DownCarrotContainer>
        </Styled.CenterWrapper>
      </Styled.Splash>
      <Product />
      <MobileAppCta />
      <Footer />
    </Styled.HomeContainer>
  );
};

export default Home;
