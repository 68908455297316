export const SensorSad = () => {
  return (
    <svg width="60%" height="auto" viewBox="0 0 402 265" fill="none">
      <rect
        x="2.5"
        y="197.121"
        width="101.246"
        height="65.3793"
        rx="6.5"
        fill="white"
        fillOpacity="0.4"
        stroke="white"
        strokeWidth="5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.375 192.831H111.089C110.127 180.719 101.952 171.259 92.0084 171.259C82.0653 171.259 73.8897 180.719 72.9277 192.831H67.6422C66.5376 192.831 65.6422 193.726 65.6422 194.831V231.579C65.6422 232.683 66.5376 233.579 67.6422 233.579H116.375C117.479 233.579 118.375 232.683 118.375 231.579V194.831C118.375 193.726 117.479 192.831 116.375 192.831ZM103.993 191.633C103.993 192.036 103.981 192.435 103.958 192.831H80.0587C80.0356 192.435 80.0238 192.036 80.0238 191.633C80.0238 183.028 85.3895 176.052 92.0084 176.052C98.6274 176.052 103.993 183.028 103.993 191.633ZM99.6106 208.411C99.6106 211.583 97.8505 214.343 95.2537 215.769V223.567C95.2537 225.224 93.9106 226.567 92.2537 226.567C90.5969 226.567 89.2537 225.224 89.2537 223.567V216.568C85.5698 215.683 82.8321 212.367 82.8321 208.411C82.8321 203.778 86.5881 200.022 91.2214 200.022C95.8546 200.022 99.6106 203.778 99.6106 208.411Z"
        fill="#FF4B4B"
      />
      <rect
        x="176.625"
        y="117.474"
        width="6.09052"
        height="13.5345"
        fill="#949494"
      />
      <rect
        x="177.978"
        y="108"
        width="3.38362"
        height="13.5345"
        fill="#949494"
      />
      <rect
        x="223.319"
        y="108"
        width="3.38362"
        height="13.5345"
        fill="#949494"
      />
      <rect
        x="221.966"
        y="117.474"
        width="6.09052"
        height="13.5345"
        fill="#949494"
      />
      <rect
        x="169.181"
        y="128.978"
        width="66.319"
        height="21.6552"
        rx="1"
        fill="#D9D9D9"
      />
      <circle cx="176.287" cy="139.468" r="2.36853" fill="#04BA00" />
      <circle cx="185.084" cy="139.468" r="2.36853" fill="#04BA00" />
      <circle cx="229.071" cy="139.468" r="2.36853" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.293 181.086C154.293 164.268 140.659 150.634 123.841 150.634V152.634C139.555 152.634 152.293 165.372 152.293 181.086H154.293Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.142 181.086C144.142 169.874 135.053 160.784 123.841 160.784V162.784C133.948 162.784 142.142 170.978 142.142 181.086H144.142Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.991 181.086C133.991 175.48 129.447 170.935 123.841 170.935V172.935C128.342 172.935 131.991 176.585 131.991 181.086H133.991Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M307 108C307 75.4152 280.585 49 248 49V51C279.48 51 305 76.5198 305 108H307Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M287.333 108C287.333 86.2768 269.723 68.6667 248 68.6667V70.6667C268.619 70.6667 285.333 87.3814 285.333 108H287.333Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M267.667 108C267.667 97.1384 258.862 88.3333 248 88.3333V90.3333C257.757 90.3333 265.667 98.243 265.667 108H267.667Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M359.103 35.8913C343.882 39.3948 332.454 52.8248 332 69H401.972C401.513 52.6459 389.836 39.0981 374.363 35.7787L370.164 43.05C368.625 45.7167 364.776 45.7167 363.236 43.05L359.103 35.8913Z"
        fill="white"
      />
      <circle cx="366.5" cy="16.5" r="16.5" fill="white" />
    </svg>
  );
};
