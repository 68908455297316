import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";

export const PathContainer = styled.div<{ smallScreen: boolean }>`
  background-color: #121212;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 80% 20%;
  grid-template-areas: "container" "carousel";
  overflow-x: hidden;
  overflow-y: hidden;
`;

// export const HappyContainer = styled.div`
//   grid-area: container;
//   width: 100%;
//   height: 100%;
//   display: grid;
//   /* grid-template-columns: 50% 50%;
//   grid-template-rows: 100%;
//   grid-template-areas: "image description"; */
//   grid-template-columns: 100%;
//   grid-template-rows: 50% 50%;
//   grid-template-areas: "image" "description";
// `;

const HappySadContainer_Small = css`
  grid-template-columns: 100%;
  grid-template-rows: 50% 50%;
  grid-template-areas: "image" "description";
`;

const HappySadContainer_Large = css`
  grid-template-columns: 50% 50%;
  grid-template-rows: 90% 10%;
  grid-template-areas: "image description" "carouselIndicator carouselIndicator";
`;

export const HappySadContainer = styled.div<{ smallScreen: boolean }>`
  grid-area: container;
  width: 100%;
  height: 100%;
  display: grid;
  ${(props) =>
    props.smallScreen ? HappySadContainer_Small : HappySadContainer_Large}
`;

export const ImageContainer = styled.div`
  grid-area: image;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  grid-area: description;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HappyLogosPlusTextContainer = styled.div<{ smallScreen: boolean }>`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: minmax(min-content, 40%) minmax(min-content, auto);
  grid-template-areas: "sensor hub" "description description";
  grid-row-gap: 32px;
  width: 100%;
  height: 80%;
  padding: ${(props) => (props.smallScreen ? 0 : "64px")};
  /* padding: 64px; */
`;

export const CenterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HubContainer = styled.div`
  grid-area: hub;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SensorContainer = styled.div`
  grid-area: sensor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: description;
`;

export const Description = styled.div<{ smallScreen: boolean }>`
  color: white;
  font-size: ${(props) => (props.smallScreen ? "24px" : "36px")};
  font-family: helvetica, arial, sans-serif;
  font-weight: normal;
  user-select: none;
  text-align: center;
  width: 80%;
`;

export const CarouselCenterWrapper = styled.div`
  grid-area: carousel;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% { 
    opacity: 30%;
  }
`;

export const CarouselButton = styled.button`
  width: 75px;
  height: 75px;
  border-radius: 100px;
  margin: 16px;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  animation: ${fadeIn} 5s ease-in-out;
  animation-delay: 3s;
  animation-fill-mode: backwards;

  &:hover:not(:disabled) {
    background: linear-gradient(
      112.68deg,
      rgba(255, 75, 75, 0.757) 0%,
      rgba(252, 176, 69, 0.757) 100%
    );
    opacity: 90%;
    transform: scale(1.05);
    transition: background 240ms ease, opacity 240ms ease, transform 240ms ease;
  }

  &:active:not(:disabled) {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
`;
