import { Menu } from "./menu";
import { DownCarrot } from "./downCarrot";
import { Close } from "./close";

export enum IconNames {
  Menu = "menu",
  DownCarrot = "downCarrot",
  Close = "close",
}

const Icons = {
  [IconNames.Menu]: Menu,
  [IconNames.DownCarrot]: DownCarrot,
  [IconNames.Close]: Close,
};

export default Icons;
