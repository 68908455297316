export const SensorHappy = () => {
  return (
    <svg
      width="60%"
      height="auto"
      viewBox="0 0 348 232"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.5"
        y="130.5"
        width="152"
        height="99"
        rx="6.5"
        fill="white"
        fillOpacity="0.4"
        stroke="white"
        strokeWidth="5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.923 123.878H164.157C162.736 105.979 150.655 92 135.962 92C121.269 92 109.187 105.979 107.766 123.878H99C97.8954 123.878 97 124.773 97 125.878V182.091C97 183.195 97.8954 184.091 99 184.091H172.923C174.028 184.091 174.923 183.195 174.923 182.091V125.878C174.923 124.773 174.028 123.878 172.923 123.878ZM153.671 122.107C153.671 122.703 153.654 123.293 153.62 123.878H118.303C118.269 123.293 118.252 122.703 118.252 122.107C118.252 109.392 126.181 99.0839 135.962 99.0839C145.742 99.0839 153.671 109.392 153.671 122.107ZM147.195 146.9C147.195 152.679 143.241 157.535 137.891 158.908V170.729C137.891 172.386 136.548 173.729 134.891 173.729C133.234 173.729 131.891 172.386 131.891 170.729V158.954C126.447 157.646 122.402 152.746 122.402 146.9C122.402 140.054 127.952 134.503 134.799 134.503C141.645 134.503 147.195 140.054 147.195 146.9Z"
        fill="#04BA00"
      />
      <rect x="261" y="14" width="9" height="20" fill="#949494" />
      <rect x="263" width="5" height="20" fill="#949494" />
      <rect x="330" width="5" height="20" fill="#949494" />
      <rect x="328" y="14" width="9" height="20" fill="#949494" />
      <rect x="250" y="31" width="98" height="32" rx="1" fill="#D9D9D9" />
      <circle cx="260.5" cy="46.5" r="3.5" fill="#04BA00" />
      <circle cx="273.5" cy="46.5" r="3.5" fill="#04BA00" />
      <circle cx="338.5" cy="46.5" r="3.5" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228 108C228 83.1472 207.853 63 183 63V65C206.748 65 226 84.2518 226 108H228Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213 108C213 91.4315 199.569 78 183 78V80C198.464 80 211 92.536 211 108H213Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198 108C198 99.7157 191.284 93 183 93V95C190.18 95 196 100.82 196 108H198Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228 108C228 83.1472 207.853 63 183 63V65C206.748 65 226 84.2518 226 108H228Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213 108C213 91.4315 199.569 78 183 78V80C198.464 80 211 92.536 211 108H213Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198 108C198 99.7157 191.284 93 183 93V95C190.18 95 196 100.82 196 108H198Z"
        fill="white"
      />
    </svg>
  );
};
