import styled from "@emotion/styled";

const FooterContainer = styled.div`
  max-height: 100px;
  width: 100%;
  background-color: black;
  color: white;
  font-size: 16px;
  font-family: serif;
  font-weight: normal;
  user-select: none;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    padding: 0 16px;
    font-size: 12px;
  }
`;

const Copyright = styled.div`
  padding-bottom: 8px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Copyright>© 2022 Storage Monitor Group LLC</Copyright>
      <span>
        Google Play and the Google Play logo are trademarks of Google LLC.
      </span>
      <span>The App Store and Apple Logo are trademarks of Apple Inc.</span>
    </FooterContainer>
  );
};

export default Footer;
