import * as Styled from "./style";
import iPhoneHero from "../../assets/iphone-12--black.png";
import GooglePlayBadge from "../../assets/google-play-badge.png";
import { useSmallScreenListener } from "../../hooks/useSmallScreenListener";
import { AppleStore } from "../../assets/graphics/AppleStore";

const MobileAppCta = () => {
  const smallScreen = useSmallScreenListener();

  return (
    <Styled.MobileAppContainer smallScreen={smallScreen}>
      <Styled.MobileHero>
        <img
          style={{ width: "auto", maxHeight: "90%" }}
          alt="Watch My Lock"
          src={iPhoneHero}
        />
      </Styled.MobileHero>
      <Styled.TextContainer>
        <Styled.TextHeader smallScreen={smallScreen}>
          Let's protect what matters
        </Styled.TextHeader>
        <Styled.DisclaimerHeader>
          Sensor availability may vary by location
        </Styled.DisclaimerHeader>
        <Styled.AvailabilityRow>
          <Styled.DownloadCard>
            <AppleStore />
          </Styled.DownloadCard>
          <Styled.DownloadCard>
            <img
              style={{ width: "auto", maxHeight: "90%" }}
              alt="Available on Google Play"
              src={GooglePlayBadge}
            />
          </Styled.DownloadCard>
        </Styled.AvailabilityRow>
      </Styled.TextContainer>
    </Styled.MobileAppContainer>
  );
};

export default MobileAppCta;
