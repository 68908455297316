import React from "react";

const SMALL_SCREEN_BREAK = 800;

export const useSmallScreenListener = (screenSizeOverride?: number) => {
  const [smallScreen, setSmallScreen] = React.useState(false);

  const screenBreakOverride = screenSizeOverride
    ? screenSizeOverride
    : SMALL_SCREEN_BREAK;

  const setScreen = () => {
    if (window.innerWidth <= screenBreakOverride) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  };

  React.useEffect(() => {
    setScreen();
    window.addEventListener("resize", setScreen);
    return () => {
      window.removeEventListener("resize", setScreen);
    };
  });

  return smallScreen;
};
