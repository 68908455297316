import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";

const bounce = keyframes`
  0% {
    transform: translate3d(0,0,0);
    opacity: 0;
  }
  70% { 
      transform: translate3d(0,30px,0);
      opacity: .7;
    }
    to { 
      transform: translate3d(0,30px,0);
      opacity: 0;
    }
`;

export const HomeContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const Splash = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(112.68deg, #414141 0%, #000000 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  cursor: default;
  display: grid;
  grid-template-rows: 90% 10%;
  grid-template-columns: 20% 60% 20%;
  grid-template-areas: ". header ." "downCarrot downCarrot downCarrot";
`;

export const CenterWrapper = styled.div`
  grid-area: downCarrot;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const DownCarrotContainer = styled.div`
  opacity: 0;
  animation: ${bounce} 8s ease infinite;
  animation-delay: 5s;
  cursor: pointer;
`;

const SplashText_Small = css`
  grid-template-columns: 100%;
  grid-gap: 16px;
  grid-template-rows: 50% 50%;
  grid-template-areas: "preText" "postText";
`;

const SplashText_Large = css`
  grid-template-columns: 50% 50%;
  grid-gap: 16px;
  grid-template-rows: 100%;
  grid-template-areas: "preText postText";
  padding: 32px;
`;

export const SplashText = styled.div<{ smallScreen: boolean }>`
  grid-area: header;
  display: grid;
  ${(props) => (props.smallScreen ? SplashText_Small : SplashText_Large)}
  width: 100%;
  height: 100%;
  font-size: 64px;
  font-family: helvetica, arial, sans-serif;
  font-weight: 200;
  user-select: none;
`;

export const SplashTextPreText = styled.span<{ smallScreen: boolean }>`
  grid-area: preText;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.smallScreen ? "flex-end" : "center")};
  text-align: ${(props) => (props.smallScreen ? "center" : "end")}; ;
`;

export const SplashTextPostText = styled.span<{ smallScreen: boolean }>`
  grid-area: postText;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.smallScreen ? "flex-start" : "center")};
  text-align: ${(props) => (props.smallScreen ? "center" : "left")}; ;
`;
