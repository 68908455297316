import styled from "@emotion/styled";
import { css } from "@emotion/react";

const mobileAppContainer_Small = css`
  grid-template-columns: 100%;
  grid-template-rows: 65% 35%;
  grid-template-areas: "hero" "text";
`;

const mobileAppContainer_Large = css`
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  grid-template-areas: "hero text";
`;

export const MobileAppContainer = styled.div<{ smallScreen: boolean }>`
  width: 100%;
  height: 100%;
  display: grid;
  ${(props) =>
    props.smallScreen ? mobileAppContainer_Small : mobileAppContainer_Large}
`;

export const MobileHero = styled.div`
  grid-area: hero;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  grid-area: text;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TextHeader = styled.h1<{ smallScreen: boolean }>`
  font-family: "Helvetica Nueue", roboto, Arial, Helvetica, sans-serif;
  text-align: center;
  padding: ${(props) => (props.smallScreen ? "8px" : "0")};
`;

export const DisclaimerHeader = styled.h5`
  font-family: "Helvetica Nueue", roboto, Arial, Helvetica, sans-serif;
  color: darkgrey;
  margin-top: -16px;
`;

export const AvailabilityRow = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 45%;
`;

export const DownloadCard = styled.button`
  width: 128px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: white;
`;
