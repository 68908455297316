import styled from "@emotion/styled";

export const MenuBarContainer = styled.div`
  width: 100%;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);

  display: grid;
  grid-template-columns:
    minmax(10%, 30%) auto minmax(150px, max-content)
    minmax(150px, max-content) minmax(150px, max-content) 10px;
  grid-template-rows: 100%;
  column-gap: 10px;
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  grid-row-start: ${(props) => props.gridRowStart};
  grid-row-end: ${(props) => props.gridRowEnd};
  grid-column-start: ${(props) => props.gridColumnStart};
  grid-column-start: ${(props) => props.gridColumnEnd};
`;

interface ButtonContainerProps {
  gridRowStart: number;
  gridRowEnd: number;
  gridColumnStart: number;
  gridColumnEnd: number;
}

export const SmallButtonContainer = styled.div<SmallButtonContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  grid-area: ${(props) => props.gridArea};
`;
interface SmallButtonContainerProps {
  gridArea: string;
}

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 32px;

  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  grid-column-end: 1;

  cursor: pointer;
`;

export const Logo = styled.img`
  width: 80%;
  height: auto;
`;

export const SmallMenuBarContainer = styled.div<SmallMenuBarContainerProps>`
  width: 100%;
  height: ${(props) => (props.menuOpen ? "100%" : "90px")};
  background-color: ${(props) =>
    props.menuOpen ? "rgba(138, 138, 138, 0.55)" : "rgba(138, 138, 138, 0.15)"};

  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;

  display: grid;
  grid-template-columns: minmax(150px, 150px) auto minmax(150px, max-content);
  grid-template-rows: 90px 90px 1px 90px 1px 90px;
  grid-template-areas:
    "logo . hamburger"
    "admin admin admin"
    ". dividerOne ."
    "about about about"
    ". dividerTwo ."
    "mobile mobile mobile";
  column-gap: 10px;
  row-gap: 20px;

  transition: height 270ms ease, background-color 270ms ease;
`;

interface SmallMenuBarContainerProps {
  menuOpen: boolean;
}

export const MenuExpansionButton = styled.div`
  grid-area: hamburger;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SmallMenuDividerTwo = styled.div`
  grid-area: dividerTwo;
  width: 100%;
  height: 100%;
  background-color: #ffffff3b;
`;

export const SmallMenuDividerOne = styled.div`
  grid-area: dividerOne;
  width: 100%;
  height: 100%;
  background-color: #ffffff3b;
`;
