import React from "react";

export const DownCarrot = () => {
  return (
    <svg width="38" height="21" viewBox="0 0 38 21" fill="none">
      <path
        d="M2.02945 2L17.5858 17.5563C18.3668 18.3374 19.6332 18.3374 20.4142 17.5563L35.9706 2"
        stroke="white"
        strokeWidth="4"
      />
    </svg>
  );
};
