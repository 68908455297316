import React from "react";

export const Menu = () => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none">
      <line
        x1="2"
        y1="9"
        x2="22"
        y2="9"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        x1="2"
        y1="2"
        x2="22"
        y2="2"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        x1="2"
        y1="16"
        x2="22"
        y2="16"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};
