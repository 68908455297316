import React from "react";
import styled from "@emotion/styled";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   IconLookup,
//   IconName,
//   IconDefinition,
//   findIconDefinition,
// } from "@fortawesome/fontawesome-svg-core";

import {
  buttonColorValues,
  ButtonSizes,
  buttonSizeValues,
  ButtonTypes,
} from "./buttonUtils";
import Icons, { IconNames } from "../../assets/icons";

const determineCustomStyling = (
  buttonType: ButtonTypes,
  buttonSizes: ButtonSizes,
  disabledState: boolean
): ButtonContainerProps => {
  return {
    color: buttonColorValues[buttonType].color,
    invertedColor: buttonColorValues[buttonType].invertedColor,
    disabled: disabledState,
    height: buttonSizeValues[buttonSizes].height,
    maxWidth: buttonSizeValues[buttonSizes].maxWidth,
    minWidth: buttonSizeValues[buttonSizes].minWidth,
    hover: buttonColorValues[buttonType].hover,
  };
};

const ButtonContainer = styled.div<ButtonContainerProps>`
  ${(props) =>
    props.preventColorInversionPreset
      ? ""
      : `border: 2px solid ${props.invertedColor};`};
  border-radius: 4px;
  width: 100%;
  min-width: ${(props) => props.minWidth}px;
  max-width: ${(props) => props.maxWidth}px;
  height: ${(props) => props.height}px;
  max-height: ${(props) => props.height}px;
  opacity: ${(props) => (props.disabled ? ".4" : "1")};

  font-family: "Helvetica Nueue", roboto, Arial, Helvetica, sans-serif;
  display: flex;
  flex-grow: 100;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  color: ${(props) => props.color};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  user-select: none;
  &:hover {
    background-color: ${(props) => props.hover};
    border: ${(props) => !props.disabled && "2px solid" + props.color};
    border-radius: 4px;
    transition: background-color 270ms ease;
  }
`;

const Button = ({
  buttonSize,
  buttonType,
  disabled = false,
  onClickHandler,
  style,
  text,
  preventColorInversionPreset = false,
  iconName,
}: Props) => {
  const buttonSyles = determineCustomStyling(buttonType, buttonSize, disabled);

  return (
    <ButtonContainer
      onClick={onClickHandler}
      {...buttonSyles}
      style={style}
      aria-label={text}
      preventColorInversionPreset={preventColorInversionPreset}
    >
      {iconName && Icons[iconName]()}
      {text && text}
    </ButtonContainer>
  );
};

// const findIcon = (name: IconName): IconDefinition => {
//   const iconLookup: IconLookup = { prefix: "fas", iconName: name };
//   return findIconDefinition(iconLookup);
// };

interface ButtonContainerProps {
  color: string;
  invertedColor: string;
  disabled: boolean;
  height: number;
  maxWidth: number;
  minWidth: number;
  preventColorInversionPreset?: boolean;
  hover: string;
}

interface Props {
  buttonType: ButtonTypes;
  buttonSize: ButtonSizes;
  onClickHandler: (event: React.MouseEvent) => void;
  text?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  preventColorInversionPreset?: boolean;
  iconName?: IconNames;
}

export default Button;
