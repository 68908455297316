import React from "react";
import { IconNames } from "../../assets/icons";
import { WML } from "../../assets/logos/WML";

import Button from "../button/button";
import { ButtonSizes, ButtonTypes } from "../button/buttonUtils";
import {
  MenuBarContainer,
  ButtonContainer,
  Logo,
  LogoContainer,
  SmallMenuBarContainer,
  MenuExpansionButton,
  SmallMenuDividerOne,
  SmallMenuDividerTwo,
  SmallButtonContainer,
} from "./menuBarStyled";

const SMALL_SCREEN_BREAK = 800;

const Menubar = () => {
  const [smallScreen, setSmallScreen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const menuLogo = require("../../assets/SMGLogo.png");

  const setScreen = () => {
    if (window.innerWidth <= SMALL_SCREEN_BREAK) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  };

  React.useEffect(() => {
    setScreen();
    window.addEventListener("resize", setScreen);
    return () => {
      window.removeEventListener("resize", setScreen);
    };
  });

  return smallScreen ? (
    <>
      <SmallMenuBarContainer menuOpen={menuOpen}>
        <LogoContainer>
          <WML />
        </LogoContainer>
        <MenuExpansionButton>
          <Button
            buttonType={ButtonTypes.Primary}
            buttonSize={ButtonSizes.Small}
            onClickHandler={(event: React.MouseEvent) => {
              setMenuOpen(!menuOpen);
            }}
            style={{
              maxWidth: "40px",
            }}
            preventColorInversionPreset
            iconName={menuOpen ? IconNames.Close : IconNames.Menu}
          />
        </MenuExpansionButton>
        {menuOpen && (
          <>
            <SmallButtonContainer gridArea={"admin"}>
              <Button
                text="Admin Console"
                buttonType={ButtonTypes.Primary}
                buttonSize={ButtonSizes.Large}
                onClickHandler={(event: React.MouseEvent) => {
                  console.log(event);
                }}
                preventColorInversionPreset
              />
            </SmallButtonContainer>

            <SmallMenuDividerOne />
            <SmallButtonContainer gridArea={"about"}>
              <Button
                text="How it Works"
                buttonType={ButtonTypes.Primary}
                buttonSize={ButtonSizes.Large}
                onClickHandler={(event: React.MouseEvent) => {
                  console.log(event);
                }}
                preventColorInversionPreset
              />
            </SmallButtonContainer>
            <SmallMenuDividerTwo />
            <SmallButtonContainer gridArea={"mobile"}>
              <Button
                text="Mobile App"
                buttonType={ButtonTypes.Primary}
                buttonSize={ButtonSizes.Large}
                onClickHandler={(event: React.MouseEvent) => {
                  console.log(event);
                }}
                preventColorInversionPreset
              />
            </SmallButtonContainer>
          </>
        )}
      </SmallMenuBarContainer>
    </>
  ) : (
    <MenuBarContainer>
      <LogoContainer>
        <WML />
      </LogoContainer>
      <ButtonContainer
        gridRowStart={1}
        gridRowEnd={1}
        gridColumnStart={3}
        gridColumnEnd={3}
      >
        <Button
          text="Admin Console"
          buttonType={ButtonTypes.Primary}
          buttonSize={ButtonSizes.Medium}
          onClickHandler={(event: React.MouseEvent) => {
            const element = document.getElementById("productSection");
            element?.scrollIntoView({ behavior: "smooth" });
          }}
          preventColorInversionPreset
        />
      </ButtonContainer>
      <ButtonContainer
        gridRowStart={1}
        gridRowEnd={1}
        gridColumnStart={4}
        gridColumnEnd={4}
      >
        <Button
          text="Mobile App"
          buttonType={ButtonTypes.Primary}
          buttonSize={ButtonSizes.Medium}
          onClickHandler={(event: React.MouseEvent) => {
            const element = document.getElementById("contactSection");
            element?.scrollIntoView({ behavior: "smooth" });
          }}
          preventColorInversionPreset
        />
      </ButtonContainer>
      <ButtonContainer
        gridRowStart={1}
        gridRowEnd={1}
        gridColumnStart={5}
        gridColumnEnd={5}
      >
        <Button
          text="How it works"
          buttonType={ButtonTypes.Primary}
          buttonSize={ButtonSizes.Medium}
          onClickHandler={(event: React.MouseEvent) => {
            const element = document.getElementById("contactSection");
            element?.scrollIntoView({ behavior: "smooth" });
          }}
          preventColorInversionPreset
        />
      </ButtonContainer>
    </MenuBarContainer>
  );
};

export default Menubar;
