import * as React from "react";
import * as Styled from "./style";
import { useSmallScreenListener } from "../../hooks/useSmallScreenListener";
import { WMLHub } from "../../assets/logos/WMLHub";
import { WMLSensor } from "../../assets/logos/WMLSensor";
import { SensorHappy } from "../../assets/graphics/SensorHappy";
import { SensorSad } from "../../assets/graphics/SensorSad";
import { ArrowRight } from "../../assets/icons/arrowRight";
import { ArrowLeft } from "../../assets/icons/arrowLeft";

enum Location {
  Happy = "happy",
  Sad = "sad",
}

type Props = {
  onRightClick: () => void;
  onLeftClick: () => void;
  shouldShowRight: boolean;
  shouldShowLeft: boolean;
};

const CarouselIndicators = ({
  onRightClick,
  onLeftClick,
  shouldShowRight,
  shouldShowLeft,
}: Props) => {
  return (
    <Styled.CarouselCenterWrapper>
      <Styled.CarouselButton onClick={onRightClick} disabled={shouldShowLeft}>
        <ArrowLeft />
      </Styled.CarouselButton>
      <Styled.CarouselButton onClick={onLeftClick} disabled={shouldShowRight}>
        <ArrowRight />
      </Styled.CarouselButton>
    </Styled.CarouselCenterWrapper>
  );
};

const ProductScreen = () => {
  const smallScreen = useSmallScreenListener();

  const [carouselState, setCarouselState] = React.useState<Location>(
    Location.Happy
  );

  const onRightClick = () => {
    setCarouselState(Location.Happy);
  };

  const onLeftClick = () => {
    setCarouselState(Location.Sad);
  };

  return (
    <>
      <Styled.PathContainer id="productSection" smallScreen={smallScreen}>
        {carouselState === Location.Happy ? (
          <Styled.HappySadContainer smallScreen={smallScreen}>
            <Styled.ImageContainer>
              <SensorHappy />
            </Styled.ImageContainer>
            <Styled.CenterWrapper>
              <Styled.HappyLogosPlusTextContainer smallScreen={smallScreen}>
                <Styled.HubContainer>
                  <WMLHub />
                </Styled.HubContainer>
                <Styled.SensorContainer>
                  <WMLSensor />
                </Styled.SensorContainer>
                <Styled.CenterWrapper style={{ gridArea: "description" }}>
                  <Styled.Description smallScreen={smallScreen}>
                    Every WatchMyLock sensor seamlessly connects to our Hub via
                    a low power signal. The sensor can last for years without
                    ever running out of power.
                  </Styled.Description>
                </Styled.CenterWrapper>
              </Styled.HappyLogosPlusTextContainer>
            </Styled.CenterWrapper>
          </Styled.HappySadContainer>
        ) : (
          <Styled.HappySadContainer smallScreen={smallScreen}>
            <Styled.ImageContainer>
              <SensorSad />
            </Styled.ImageContainer>
            <Styled.DescriptionContainer>
              <Styled.Description smallScreen={smallScreen}>
                When something happens to your belongings, every second counts.
                That's why whenever our sensor is triggered we immediately let
                you know.
              </Styled.Description>
            </Styled.DescriptionContainer>
          </Styled.HappySadContainer>
        )}
        <CarouselIndicators
          onRightClick={onRightClick}
          onLeftClick={onLeftClick}
          shouldShowRight={carouselState !== Location.Happy}
          shouldShowLeft={carouselState !== Location.Sad}
        />
      </Styled.PathContainer>
    </>
  );
};

export default ProductScreen;
