import React from "react";

export const Close = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.18198 0.93934C2.59619 0.353553 1.64645 0.353553 1.06066 0.93934C0.474874 1.52513 0.474874 2.47487 1.06066 3.06066L9.87868 11.8787L0.93934 20.818C0.353553 21.4038 0.353553 22.3536 0.93934 22.9393C1.52513 23.5251 2.47487 23.5251 3.06066 22.9393L12 14L20.9393 22.9393C21.5251 23.5251 22.4749 23.5251 23.0607 22.9393C23.6464 22.3535 23.6464 21.4038 23.0607 20.818L14.1213 11.8787L22.9393 3.06067C23.5251 2.47488 23.5251 1.52513 22.9393 0.939345C22.3535 0.353558 21.4038 0.353559 20.818 0.939345L12 9.75736L3.18198 0.93934Z"
        fill="white"
      />
    </svg>
  );
};
